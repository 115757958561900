import ReportingProductInUse from "./inuse";
import ReportingProductStock from "./stock";


if (document.body.getAttribute("data-current-path") === "reporting/product") {
    const action = document.body.getAttribute("data-current-action")
    if (action === "stock") {
        new ReportingProductStock();
    } else  if (action === "inuse") {
        new ReportingProductInUse();
    }
}